import $ from 'jquery';
import 'core-js';
import 'slick-carousel/slick/slick.min';
import 'slick-carousel/slick/slick.scss';
import './slider.scss';

class Slider {
    constructor() {
        this.sliders = $('.js-slider');

        this.sliders.each((i, slider) => {
            const type = $(slider).data('type');
            const count = $(slider).find('div').length;
            $(slider).slick(Slider.getParameters(type, count));
        });
    }

    static getParameters(type, count) {
        const defaultParam = {
            infinite: true,
            prevArrow: "<button type='button' class='slick-btn prev'></button>",
            nextArrow: "<button type='button' class='slick-btn next'></button>",
            dots: false,
            slidesToScroll: 1,
            variableWidth: true,
        };

        let param = null;

        switch (type) {
        case 'advantage':
            defaultParam.prevArrow = false;
            defaultParam.nextArrow = false;
            defaultParam.infinite = false;

            param = {
                ...defaultParam,
                swipe: false,
                centerMode: false,
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            infinite: true,
                            dots: true,
                            dotsClass: 'slick-dots slick-dots--white',
                            arrows: false,
                            centerMode: true,
                            swipe: true,
                        },
                    },
                ],
            };
            return param;
        case 'catalog':
            param = {
                ...defaultParam,
                responsive: [
                    {
                        breakpoint: 1023,
                        settings: {
                            dots: true,
                            arrows: false,
                            centerMode: true,
                        },
                    },
                    {
                        breakpoint: 699,
                        settings: {
                            centerMode: false,
                            dots: true,
                            arrows: false,
                        },
                    },
                ],
            };
            return param;

        case 'article':
            defaultParam.prevArrow = false;
            defaultParam.nextArrow = false;
            param = {
                ...defaultParam,
                centerMode: true,
                autoplay: true,
                dots: true,
            };
            return param;

        case 'product-info':
            defaultParam.variableWidth = false;
            param = {
                ...defaultParam,
                arrows: false,
                dots: count !== 1,
                slidesToShow: 1,
            };
            return param;
        default:
            param = {
                ...defaultParam,
                centerMode: true,
                responsive: [
                    {
                        breakpoint: 1023,
                        settings: {
                            centerMode: false,
                            dots: true,
                            arrows: false,
                        },
                    },
                    {
                        breakpoint: 400,
                        settings: {
                            centerMode: true,
                            dots: true,
                            arrows: false,
                        },
                    },
                ],
            };
            return param;
        }
    }
}

new Slider();
