import $ from 'jquery';
import './typography.scss';

/**
 * Оборачивает таблицы дивом с горизонтальной прокруткой
 * @param {tables} - jquery obj таблиц в .typograph"
 */
const wrappingTables = (tables) => {
    tables.wrap("<div class='table-responsive'></div>");
};

const tables = $('.typography table');
wrappingTables(tables);
