// ==========================================================================
// Dependencies
// ==========================================================================

import '../components/ui/default';
import '../components/ui/button';
import '../components/ui/footer';
import '../components/ui/grid';
import '../components/ui/header';
import '../components/ui/wrapper';
import '../components/ui/menu';
import '../components/ui/specialslider';
import '../components/ui/advantages';
import '../components/ui/articles';
import '../components/ui/feedback';
import '../components/ui/reviews';
import '../components/ui/slider';
import '../components/ui/input';
import '../components/ui/response-full-phone-name';
import '../components/ui/header_main';
import '../components/ui/articles_list';
import '../components/ui/article';
import '../components/ui/typography';
import '../components/ui/breadcrumbs';
import '../components/ui/pagination';
import '../components/ui/page';
import '../components/ui/school';
import '../components/ui/request-form';
import '../components/ui/entity_list';
import '../components/ui/burger-menu';
import '../components/ui/mobile-menu';
