import './request-form.scss';

import $ from 'jquery';
import FormValidator from 'validate-js';

export default class Form {
    constructor($form) {
        this.form = $form;
        this.name = $(this.form).attr('name');
        this.body = $(this.form).find('.js-form-body');
        this.message = $(this.form).find('.js-form-message');
        this.submitBtn = $(this.form).find('.js-form-submit');
        this.clearBtn = $(this.form).find('.js-form-clear');

        this.init();
    }

    request() {
        const formData = $(this.form).serialize();
        const formAction = $(this.form).attr('action');
        const { form } = this;
        const { body } = this;
        const { message } = this;
        const isHiddenForm = $(form).length > 0 && $(message).length > 0;

        $.post(formAction, formData, (result) => {
            if (result.success) {
                console.log($(message).length > 0);
                if (isHiddenForm) {
                    $(body).addClass('hidden');
                    $(message).addClass('active');
                    $(document).trigger('hiddenForm');
                } else {
                    $(form).submit();
                }
            } else {
                console.log(result.error);
            }
        });
    }

    setState(errorList, fields) {
        $(fields).each((i, field) => {
            const elementState = this.getElementState(errorList, field);

            if (elementState === 'error') {
                this.setErrorState(field);
            } else {
                this.setValidState(field);
            }
        });
    }

    getErrorList(errors) {
        const errorList = [];

        [].forEach.call(errors, (error) => {
            errorList.push(error.element);
        });

        return errorList;
    }

    getElementState(errorlist, elem) {
        let state = 'valid';
        [].forEach.call(errorlist, (errorElement) => {
            if (elem.isEqualNode(errorElement)) {
                state = 'error';
                return false;
            }
            return false;
        });
        return state;
    }

    setValidState(elem) {
        $(elem).removeClass('_error');
        $(elem).addClass('_valid');
    }

    setErrorState(elem) {
        $(elem).removeClass('_valid');
        $(elem).addClass('_error');
    }

    setValidStateAll(fields) {
        $(fields).each((i, field) => {
            this.setValidState(field);
        });
    }

    clearForm() {
        $('.js-select').each((i, select) => {
            $(select).val('').trigger('change');
        });
    }

    validate(fields) {
        const parametres = [{
            name: 'name',
            display: 'required',
            rules: 'required|min_length[3]',
        }, {
            name: 'phone',
            display: 'required',
            rules: 'required|callback_check_phone',
        }, {
            name: 'email',
            rules: 'required|valid_email',
        }, {
            name: 'comment',
            display: 'required',
            rules: 'required',
        }, {
            name: 'address',
            display: 'required',
            rules: 'required',
            depends(field) {
                if (field.element.classList.contains('hidden')) {
                    return false;
                }
                return true;
            },
        }];

        const validator = new FormValidator(this.name, parametres, ((errors, event) => {
            event.preventDefault();

            if (errors.length > 0) {
                const errorList = this.getErrorList(errors);
                this.setState(errorList, fields);
            } else {
                this.setValidStateAll(fields);
                this.request();
            }
        }));

        validator.registerCallback('check_phone', (value) => {
            const reg = /\+7\s\(\d{3}\)\s\d{3}\s-\s\d{2}\s-\s\d{2}/;
            if (reg.test(value)) {
                return true;
            }
            return false;
        });
    }

    handleSubmit() {
        $(this.submitBtn).on('click', (e) => {
            e.preventDefault();
            this.request();
        });
    }

    handleClearForm() {
        $(this.clearBtn).on('click', (e) => {
            e.preventDefault();
            this.clearForm();
        });
    }

    init() {
        const fields = $(this.form).find('.js-form-field:not(.hidden)');

        if ($(this.clearBtn).length > 0) {
            this.handleClearForm();
        }

        // if ($(fields).length > 0) {
        //     this.validate(fields);
        // } else {
            this.handleSubmit();
        // }
    }
}

$('.js-form').each((i, form) => {
    new Form(form);
});
