import './input.scss';
import Inputmask from 'inputmask';

const phone = document.querySelectorAll('.js-input-phone');
const date = document.querySelectorAll('.js-input-date');

[].forEach.call(phone, (input) => {
    new Inputmask('+7 (999) 999 - 99 - 99').mask(input);
});

[].forEach.call(date, (input) => {
    new Inputmask('99.99.9999').mask(input);
});
